import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import UnauthenticatedHome from "./UnauthenticatedHome"
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, getDoc, getFirestore, setDoc, collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import Page from "../Components/Page";
import { useState, useEffect } from "react";
import { Employee } from "../App";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { Sickness } from "./HrPortal/HrSickness";
import moment from "moment";
import names from "../Data/names.json"
import { SelfCertForm } from "./Sickness/SelfCert";
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import sickData from "../Data/sick.json"
import { v4 as uuidv4 } from 'uuid';
import { BiSolidCalendar } from "react-icons/bi";
import logo from "../Images/logoWhite.png"
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
export interface graphAPIData {
    businessPhone: []
    displayName: string
    givenName: string
    id: string;
    jobTitle: string
    mail: string
    surname: string
}

export interface User {
    name: string;
    id: string;
    email: string
}

export interface Event {
    id: string
    comments?: string
    reason: string
    type: string
    daysTaken: number
    allDay?: boolean
    start: string
    end: string
    employee: User
    manager?: string
    access: string[]
    hrStatus: string
    managerStatus: string
    rejectReason?: string
}

export interface CalEvent {
    title: string
    allDay?: boolean
    start: Date
    end: Date
}

export interface Email {
    to: string[]
    message: {
        subject: string
        text?: string
        html?: string
    }
}

const Home = () => {
    const [userDetails, setUserDetails] = useState<Employee>({
        access: [],
        approver: "",
        daysForService: 0,
        daysToUse: 0,
        email: "",
        entitlement: 0,
        fullName: "",
        startDate: "",
        xmas: 0,
        carryOver: 0,
        inLieu: 0
    })
    const [allEmployees, setAllEmployees] = useState<Employee[]>([])
    const [events, setEvents] = useState<Event[]>([])
    const [manEvents, setManEvents] = useState<Event[]>([])
    const [manCancelEvents, setManCancelEvents] = useState<Event[]>([])
    const [allEvents, setAllEvents] = useState<Event[]>([])
    const [threeSick, setThreeSick] = useState<boolean>(false)
    const [allSickness, setAllSickness] = useState<Sickness[]>([])
    const [allSelfCerts, setAllSelfCerts] = useState<{ selfCert: SelfCertForm }[]>([])
    const [yearSelection, setYearSelection] = useState<string>("2025")
    const [bankHolidays, setBankHolidays] = useState<CalEvent[]>([])
    const [accessMan, setAccessMan] = useState<boolean>(false)
    const [allManagers, setAllManagers] = useState<string[]>([])
    const [accessIndex, setAcessIndex] = useState<number>(0)
    const [showB, setShowB] = useState(false);

    const toggleShowB = () => setShowB(!showB);
    const firebaseConfig = {
        apiKey: "AIzaSyAc2YZNMDq5pp54eskYesRxVeiav9E9p0c",
        authDomain: "holiday-sickness.firebaseapp.com",
        projectId: "holiday-sickness",
        storageBucket: "holiday-sickness.appspot.com",
        messagingSenderId: "1032502450843",
        appId: "1:1032502450843:web:5e2faff01d9defb9a0443e",
        measurementId: "G-7ZJ99MJM1P"
    };



    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getFirestore(app);

    const getUserDetails = async (email: string) => {
        if (email !== "") {
            if (yearSelection === "2024") {
                const docRef = doc(db, "Employees", email.toLowerCase());
                const docSnap = await getDoc(docRef);
                //@ts-ignore
                setUserDetails(docSnap.data())
            }
            if (yearSelection === "2025") {
                const docRef = doc(db, "Employees2025", email.toLowerCase());
                const docSnap = await getDoc(docRef);
                //@ts-ignore
                setUserDetails(docSnap.data())
            }
        }
    }

    const getAllUsers = async () => {
        const temp: Employee[] = []
        if (yearSelection === "2024") {
            const querySnapshot = await getDocs(collection(db, "Employees"));
            querySnapshot.forEach((doc) => {
                //@ts-ignore-+*//
                temp.push(doc.data())
            });
        }
        if (yearSelection === "2025") {
            const querySnapshot = await getDocs(collection(db, "Employees2025"));
            querySnapshot.forEach((doc) => {
                //@ts-ignore-+*//
                temp.push(doc.data())
            });
        }
        setAllEmployees(temp)
    }

    const sendManagerEmail = async (event: Event) => {
        console.log(event)
        if (event.manager) {
            const temp: Email = {
                to: [event.manager],
                message: {
                    subject: `A new holiday has been requested for ${yearSelection}`,
                    html: `<p>Hello,</p>
                    <p>${event.employee.name} has requested a holiday for ${yearSelection}. Please approve or deny this in the holiday portal.</p>
                    <a href="https://eurosafesolutions-holidays-sickness.co.uk/">Click here to view request</a>
                    <p>Thank you</p>
                    `
                }
            }
            await setDoc(doc(db, "mail", event.id), temp);
        }
    }

    const sendSelfCert = (ids: GridRowSelectionModel) => {
        ids.map(async (id) => {
            const docRef = doc(db, "Sickness", id.toString());
            const docSnap = await getDoc(docRef);
            //@ts-ignore
            const sickness: Sickness = docSnap.data()
            if (sickness.selfCert === "Awaiting Self-Cert") {
                const temp: Email = {
                    to: [sickness.user.email],
                    message: {
                        subject: `Eurosafe sickness self-cert`,
                        html: `<p>Hello,</p>
                        <p>You are required to fill in a sickness self-cert. Follow the steps below to complete.</p>
                        <p>1) Go to the sickness section on the holiday portal</p>
                        <p>2) Select your instance of sickness</p>
                        <p>3) Press the complete self cert button</p>
                        <p>4) Fill out form</p>
                        <a href="https://eurosafesolutions-holidays-sickness.co.uk/">Click here to view sickness</a>
                        <p>Thank you</p>
                        `
                    }
                }
                await setDoc(doc(db, "mail", sickness.id), temp);
            }
        })
    }

    const sendStatusEmail = async (event: Event, status: string, reason?: string) => {
        if (event.employee) {
            let temp: Email = {
                to: [event.employee.email],
                message: {
                    subject: `Your holiday for ${yearSelection} request has been ${status}`,
                    html: `<p>Hello,</p>
                    <p>Your holiday request starting on the ${moment(event.start).format("DD/MM/yyyy")} has been ${status}.</p>
                    <p>Thank you</p>
                    `
                }
            }
            if (reason !== "" || reason !== undefined) {
                temp = {
                    to: [event.employee.email],
                    message: {
                        subject: `Your holiday request for ${yearSelection} has been ${status}`,
                        html: `<p>Hello,</p>
                        <p>Your holiday request starting on the ${moment(event.start).format("DD/MM/yyyy")} has been ${status}.</p>
                        <p>${reason}</p>
                        <p>Thank you</p>
                        `
                    }
                }
            }
            await setDoc(doc(db, "mail", event.id), temp);
        }
    }

    //GET EVENTS
    const getEvents = async () => {
        const temp: Event[] = []
        const manTemp: Event[] = []
        const manCancelTemp: Event[] = []
        const allTemp: Event[] = []
        const querySnapshot = await getDocs(collection(db, "Events"));
        querySnapshot.forEach((doc) => {
            const dateMoment = moment(doc.data().start, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
            if (dateMoment.year().toString() === yearSelection) {
                console.log(doc.data())
                if (userDetails.email.toLowerCase() === doc.data().employee.email.toLowerCase()) {
                    //@ts-ignore
                    temp.push(doc.data())
                }
                if (doc.data().access.includes(userDetails.email.toLowerCase()) && doc.data().hrStatus === "Approved" && doc.data().managerStatus !== "Cancel requested") {
                    //@ts-ignore
                    manTemp.push(doc.data())
                }
                if (doc.data().access.includes(userDetails.email.toLowerCase()) && doc.data().managerStatus === "Cancel requested") {
                    //@ts-ignore
                    manCancelTemp.push(doc.data())
                }
                //@ts-ignore
                allTemp.push(doc.data())
            }
        });
        const docRef = doc(db, "Managers", "list");
        const docSnap = await getDoc(docRef);
        //@ts-ignore
        setAllManagers(docSnap.data().values)
        setEvents(temp)
        setManEvents(manTemp)
        setManCancelEvents(manCancelTemp)
        setAllEvents(allTemp)
    }

    //ADD EVENT DOC
    const addEvent = async (event: Event) => {
        await setDoc(doc(db, "Events", event.id), event);
        getEvents()
    }

    //UPDATE EVENT HR STATUS
    const hrUpdateStatus = (status: string, ids: GridRowSelectionModel, reason?: string) => {
        Promise.all(ids.map(async (id) => {
            const eventRef = doc(db, "Events", id.toString());
            const eventSnap = await getDoc(eventRef);
            //@ts-ignore
            const temp: Event = eventSnap.data()
            if (status === "Rejected") {
                const docRef = doc(db, "Events", id.toString());
                await updateDoc(docRef, {
                    hrStatus: status,
                    managerStatus: "Rejected",
                    rejectReason: reason
                })
                toast.success(`Holiday rejected for ${temp.employee.name}`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                sendStatusEmail(temp, "rejected", reason)
            }
            if (status === "Approved") {
                const docRef = doc(db, "Events", id.toString());
                await updateDoc(docRef, {
                    hrStatus: status,
                    managerStatus: "Pending Approval",
                })
                toast.success(`Holiday approved for ${temp.employee.name}`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                sendManagerEmail(temp)
            }
            if (status === "ApprovedManager") {
                const docRef = doc(db, "Events", id.toString());
                await updateDoc(docRef, {
                    hrStatus: "Approved",
                    managerStatus: "Approved",
                })
                toast.success(`Holiday approved for ${temp.employee.name}`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                sendStatusEmail(temp, "approved")
            }
        })).then(() => getEvents())
    }

    //UPDATE EVENT MANAGER STATUS
    const manUpdateStatus = (status: string, ids: GridRowSelectionModel, reason?: string) => {
        Promise.all(ids.map(async (id) => {
            const eventRef = doc(db, "Events", id.toString());
            const eventSnap = await getDoc(eventRef);
            //@ts-ignore
            const temp: Event = eventSnap.data()
            if (temp.manager?.toLowerCase() === userDetails.email.toLowerCase()) {
                const docRef = doc(db, "Events", id.toString());
                await updateDoc(docRef, {
                    managerStatus: status
                })
                if (status === "Approved") {
                    sendStatusEmail(temp, "approved")
                    toast.success(`Holiday approved for ${temp.employee.name}`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

                if (status === "Rejected") {
                    sendStatusEmail(temp, "rejected", reason)
                    toast.success(`Holiday rejected for ${temp.employee.name}`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.error(`You don't have access to approve/reject holidays for ${temp.employee.name}`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        })).then(() => getEvents())
    }

    //UPDATE SICKNESS STATUS
    const sickUpdateStatus = async (type: string, ids: GridRowSelectionModel) => {
        ids.map(async (id) => {
            if (type === "Return") {
                const docRef = doc(db, "Sickness", id.toString());
                await updateDoc(docRef, {
                    end: new Date().toString(),
                    status: "Returned"
                })
            }
            if (type === "Self Cert") {
                const docRef = doc(db, "Sickness", id.toString());
                await updateDoc(docRef, {
                    selfCert: "Completed"
                })
            }
        })
        sendSelfCert(ids)
        getSickness()
    }

    //ADD SICKNESS DOC
    const addSickness = async (sickness: Sickness) => {
        await setDoc(doc(db, "Sickness", sickness.id), sickness);
        getSickness()
    }

    //GET SICKNESS
    const getSickness = async () => {
        const allTemp: Sickness[] = []
        const querySnapshot = await getDocs(collection(db, "Sickness"));
        querySnapshot.forEach(async (document) => {
            // @ts-ignore
            allTemp.push(document.data())
        });
        setAllSickness(allTemp)
    }

    //GET SICKNESS
    const getSelfCerts = async () => {
        const allTemp: { selfCert: SelfCertForm }[] = []
        const querySnapshot = await getDocs(collection(db, "SelfCerts"));
        querySnapshot.forEach(async (document) => {
            // @ts-ignore
            allTemp.push(document.data())
        });
        setAllSelfCerts(allTemp)
    }

    const getBankHolidays = () => {
        const BankTemp: CalEvent[] = []
        if (yearSelection === "2024") {
            if (userDetails.xmas === 3) {
                BankTemp.push({
                    start: new Date("Fri Dec 27 2024 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    end: new Date("Tue Dec 31 2024 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    title: "Christmas Shutdown Period",
                    allDay: true
                })
            }
            if (userDetails.xmas === 4) {
                BankTemp.push({
                    start: new Date("Tue Dec 24 2024 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    end: new Date("Tue Dec 31 2024 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    title: "Christmas Shutdown Period",
                    allDay: true
                })
            }
        }
        if (yearSelection === "2025") {
            BankTemp.push({
                start: new Date("Fri Jun 27 2025 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                end: new Date("Fri Jun 27 2025 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                title: "Extra leave - ES 25th Birthday",
                allDay: true
            })
            if (userDetails.xmas === 3) {
                BankTemp.push({
                    start: new Date("Mon Dec 29 2025 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    end: new Date("Wed Dec 31 2025 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    title: "Christmas Shutdown Period",
                    allDay: true
                })
            }
            if (userDetails.xmas === 4) {
                BankTemp.push({
                    start: new Date("Wed Dec 24 2025 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    end: new Date("Wed Dec 31 2025 10:44:49 GMT+0000 (Greenwich Mean Time)"),
                    title: "Christmas Shutdown Period",
                    allDay: true
                })
            }
        }
        fetch('https://www.gov.uk/bank-holidays.json')
            .then(response => response.json())
            .then(data => data["england-and-wales"]["events"].map((event: any) => {
                const bankHol: CalEvent = {
                    title: `Bank Holiday - ${event.title}`,
                    allDay: true,
                    start: new Date(event.date),
                    end: new Date(event.date),
                }
                BankTemp.push(bankHol)
            }))
            .catch(error => console.error(error));
        setBankHolidays(BankTemp)
    }

    const isInLastTwelveMonths = (date: string) => {
        return moment().diff(date, "days") < 366
    }

    const checkThreeSick = async (user: User) => {
        const allTemp: Sickness[] = []
        const querySnapshot = await getDocs(collection(db, "Sickness"));
        querySnapshot.forEach(async (document) => {
            if (user.email.toLowerCase() === document.data().user.email.toLowerCase() && document.data().type === "Paid Sickness" && isInLastTwelveMonths(document.data().start)) {
                // @ts-ignore
                allTemp.push(document.data())
            }
        });
        if (allTemp.length > 2) {
            setThreeSick(true)
        } else {
            setThreeSick(false)
        }
    }

    const importData = async () => {
        let temp: string[] = []
        allEvents.map((event) => {
            temp = [...temp, ...event.access]
        })
        temp = temp.filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
        })
        await setDoc(doc(db, "Managers", "list"), { values: temp });
    }

    const createNewUser = async (emp: Employee) => {
        const temp = allManagers
        emp.access.map((acc) => {
            if (!allManagers.includes(acc)) {
                temp.push(acc)
            }
        })
        if (!allManagers.includes(emp.approver)) {
            temp.push(emp.approver)
        }
        await setDoc(doc(db, "Managers", "list"), { values: temp });
        if (yearSelection === "2024") {
            await setDoc(doc(db, "Employees", emp.email.toLowerCase()), emp);

        }
        if (yearSelection === "2025") {
            await setDoc(doc(db, "Employees2025", emp.email.toLowerCase()), emp);
        }
        getAllUsers()
    }

    const deleteEvent = async (event: Event) => {
        await deleteDoc(doc(db, "Events", event.id));
        getEvents()
    }
    const deleteEventByIds = async (ids: string[]) => {
        ids.map(async (id) => {
            await deleteDoc(doc(db, "Events", id));
        })
    }

    const delUser = async (emp: Employee) => {
        await deleteDoc(doc(db, "Employees", emp.email));
    }

    useEffect(() => {
        getEvents()
        getAllUsers()
        getSickness()
        getBankHolidays()
        getSelfCerts()
    }, [userDetails])

    useEffect(() => {
        getEvents()
        getAllUsers()
        getBankHolidays()
    }, [yearSelection])


    const updateRecords = async () => {
        const nathEmpsApprove: string[] = []
        const nathEmpsView: string[] = []
        const querySnapshot = await getDocs(collection(db, "Employees"));
        querySnapshot.forEach((doc) => {
            //@ts-ignore
            const old: Employee = doc.data()
            if (old.approver === "nwood@eurosafeuk.com") {
                nathEmpsApprove.push(old.email)
            }
            if (old.access.includes("nwood@eurosafeuk.com")) {
                nathEmpsView.push(old.email)
            }
        });
        const queryEventsSnapshot = await getDocs(collection(db, "Events"));
        queryEventsSnapshot.forEach(async (doc) => {
            //@ts-ignore
            let event: Event = doc.data()
            if (nathEmpsApprove.includes(event.employee.email)) {
                console.log("Updating approver for " + event.employee.name + " for " + event.start)
                event.manager = "nwood@eurosafeuk.com"
            }
            if (nathEmpsView.includes(event.employee.email)) {
                console.log("Updating access for " + event.employee.name + " for " + event.start)
                event.access.push("nwood@eurosafeuk.com")
            }
            createEvent(event)
        });

    }

    const createEvent = async (event: Event) => {
        console.log("Saving: " + event.employee.email)
        await setDoc(doc(db, "Events", event.id), event);
    }


    const createEmp = async (emp: Employee) => {

        await setDoc(doc(db, "Employees", emp.email), emp);
    }

    const findEmp = (empName: string) => {
        if (empName === "Liam Powell") {
            return "liampowell@eurosafeuk.com"
        }
        if (empName === "Chris Wright") {
            return "chriswright@eurosafeuk.com"
        }
        if (empName === "Caitlin Hopson") {
            return "caitlinhopson@eurosafeuk.com"
        }
        if (empName === "Laura O'Sullivan") {
            return "losullivan@eurosafeuk.com"
        }
        if (empName === "Felix McAllister-Graham") {
            return "fMcAllisterGraham@eurosafeuk.com"
        }
        if (empName === "Daniel Smith") {
            return "danielsmith@eurosafeuk.com"
        }
        if (empName === "Daniel Wright") {
            return "danielwright@eurosafeuk.com"
        }
        return empName.charAt(0) + empName.split(" ")[1] + "@eurosafeuk.com"
    }
    const importNameData = async () => {
        const miss: string[] = []
        sickData.map(async (sickness: any) => {
            const findReportedByUser = allEmployees.filter((emp) => emp.email.toLowerCase() === findEmp(sickness["Reported by"]).toLowerCase())[0]
            const findUser = allEmployees.filter((emp) => emp.email.toLowerCase() === findEmp(sickness["Sick employee"]).toLowerCase())[0]
            if (findReportedByUser === undefined) {
                miss.push(sickness["Reported by"])
            }
            if (findUser === undefined) {
                miss.push(sickness["Sick employee"])

            }
            const id = uuidv4()
            const temp: Sickness = {
                end: moment(sickness["Returned date"], "DD/MM/YYYY").toString(),
                id: id,
                reason: sickness["Extra details"].toString(),
                reportedBy: { email: findReportedByUser.email, id: "", name: findReportedByUser.fullName },
                selfCert: sickness["Self-cert complete"] === "Yes" ? "Completed" : "Awaiting Self-Cert",
                start: moment(sickness["Absence start date"], "DD/MM/YYYY").toString(),
                status: sickness.Returned,
                type: sickness["Paid/Unpaid"] === "Paid" ? "Paid Sickness" : "Unpaid Sickness",
                user: { email: findUser.email, id: "", name: findUser.fullName },

            }
            await setDoc(doc(db, "Sickness", id), temp);
        })
        const t = new Set(miss)
        console.log(t)
    }

    const completeSelfCert = async (selfCert: SelfCertForm, sickness: Sickness) => {
        try {
            if (selfCert.selectedFile) {
                let extension = '';
                const storage = getStorage();
                if (selfCert.selectedFile.type.includes('image')) {
                    extension = 'jpg'; // Change as needed based on supported image types
                } else if (selfCert.selectedFile.type.includes('pdf')) {
                    extension = 'pdf';
                } else {
                    throw new Error('Unsupported file type');
                }

                const fileName = `${sickness.id}.${extension}`;
                const storageRef = ref(storage, `DoctorsNotes/${fileName}`);
                selfCert.fileId = fileName
                await uploadBytes(storageRef, selfCert.selectedFile);
                selfCert.selectedFile = null
            }

            // Once uploaded, update Firestore documents
            await setDoc(doc(db, "SelfCerts", selfCert.sicknessId), { selfCert });
            const docRef = doc(db, "Sickness", sickness.id.toString());
            await updateDoc(docRef, {
                selfCert: "Completed",
                reason: selfCert.reason,
                end: selfCert.returnDate?.toString() || "",
            });

            console.log('File upload and document updates successful');
        } catch (error) {
            console.error('Error:', error);
            // Handle errors accordingly
        }
    };

    const delSickness = async (id: string) => {
        await deleteDoc(doc(db, "Sickness", id));
        getSickness()
    }

    const requestDel = async (event: Event) => {
        let temp = event
        temp.managerStatus = "Cancel requested"
        await setDoc(doc(db, "Events", temp.id), temp);
        getEvents()
    }

    const addInLieu = async () => {
        const temp2024: Employee[] = []
        const temp2025: Employee[] = []
        const querySnapshot = await getDocs(collection(db, "Employees"));
        querySnapshot.forEach((doc) => {
            //@ts-ignore-+*//
            temp2024.push(doc.data())
        });
        const querySnapshot2025 = await getDocs(collection(db, "Employees2025"));
        querySnapshot2025.forEach((doc) => {
            //@ts-ignore-+*//
            temp2025.push(doc.data())
        });
        temp2024.map(async (emp: Employee) => {
            emp.inLieu = 0;
            await setDoc(doc(db, "Employees", emp.email), emp);
        })
        temp2025.map(async (emp: Employee) => {
            emp.inLieu = 0;
            await setDoc(doc(db, "Employees2025", emp.email), emp);
        })
    }

    const giveUserAccess = async (user: string, access: string) => {
        allEvents.map(async (event, index) => {
            const temp = event
            if (temp.employee.email.toLowerCase() === user.toLowerCase() && !temp.access.includes(access.toLowerCase())) {
                temp.access.push(access.toLowerCase())
                await setDoc(doc(db, "Events", temp.id), temp);
                console.log(temp.start + " Updated with access")
            }

        })
    }

    const deleteEventsStartingIn2024 = async () => {
        const db = getFirestore();
        const eventsRef = collection(db, "Events");
    
        try {
            // Fetch all events from the "Events" collection
            const snapshot = await getDocs(eventsRef);
    
            const batchDeletes: any[] = [];
            snapshot.forEach((eventDoc) => {
                const eventData = eventDoc.data();
                const startDate = new Date(eventData.start);
    
                // Check if the year of the start date is 2024
                if (startDate.getFullYear() === 2024) {
                    // Add deletion to batch
                    batchDeletes.push(deleteDoc(doc(db, "Events", eventDoc.id)));
                }
            });
    
            // Execute all deletions
            await Promise.all(batchDeletes);
    
            console.log("Deleted all events starting in 2024.");
        } catch (error) {
            console.error("Error deleting events:", error);
        }
    };
    return (
        <>
            <UnauthenticatedTemplate>
                <UnauthenticatedHome importData={() => updateRecords()} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                {/* {yearSelection === "Choosing" &&
                    <div className="selector">
                        <div className="selector-container">
                            <img className="unAuth-login-block__logo m-0" src={logo} />
                            <p className="selector-container__text">Select a holiday calendar year</p>
                            <div className="d-flex flex-row">
                                <a className="selector-container-item selector-container-item--left" onClick={() => setYearSelection("2024")}><BiSolidCalendar size={45} />2024</a>
                                <a className="selector-container-item selector-container-item--right" onClick={() => setYearSelection("2025")}><BiSolidCalendar size={45} />2025</a>
                            </div>
                        </div>
                    </div>
                } */}
                {yearSelection === "2025" &&
                    <Page
                        giveUserAccess={(user, access) => giveUserAccess(user, access)}
                        backToSelect={() => setYearSelection("Choosing")}
                        yearSelection={yearSelection}
                        requestDel={(event) => requestDel(event)}
                        deleteSickness={(id: string) => delSickness(id)}
                        submitSelfCert={(selfCert, sickness) => completeSelfCert(selfCert, sickness).then(() => getSickness())}
                        allManagers={allManagers}
                        delHolsId={(ids) => deleteEventByIds(ids).then(() => getEvents())}
                        delHol={(event) => deleteEvent(event).then(() => getEvents())}
                        bankHolidays={bankHolidays}
                        createNewUser={(emp) => createNewUser(emp)}
                        importData={() => importData()}
                        threeSick={threeSick}
                        checkThreeSick={(user) => checkThreeSick(user)}
                        updateSickness={(ids, type) => sickUpdateStatus(type, ids).then(() => getSickness())}
                        createSickness={
                            (sickness) => addSickness(sickness)
                                .then(() => getSickness())
                        }
                        addEvent={
                            (event) => addEvent(event)
                                .then(() => getEvents())
                        }
                        getUserDetails={
                            (email) => getUserDetails(email)
                        }
                        hrUpdateStatus={
                            (status, ids, reason) => hrUpdateStatus(status, ids, reason)
                        }
                        manUpdateStatus={
                            (status, ids, reason) => manUpdateStatus(status, ids, reason)
                        }
                        employees={allEmployees}
                        events={events}
                        allEvents={allEvents}
                        allSickness={allSickness}
                        allSelfCerts={allSelfCerts}
                        manEvents={manEvents}
                        manCancelEvents={manCancelEvents}
                        userDetails={userDetails}
                    />
                }
            </AuthenticatedTemplate>
        </>
    )
}

export default Home